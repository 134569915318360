import clsx from 'clsx';
import React from 'react';
import useOffSetTop from 'src/hooks/useOffSetTop';
import { PATH_PAGE } from 'src/routes/paths';
import { makeStyles, alpha } from '@material-ui/core/styles';
import useAuth from 'src/hooks/useAuth';
import Account from './Account';
import {
  Box,
  Link,
  Button,
  AppBar,
  Toolbar,
  Container
} from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[0]
  },
  toolbar: {
    height: APP_BAR_MOBILE,
    transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up('md')]: {
      height: APP_BAR_DESKTOP
    }
  },
  isHome: {
    color: theme.palette.primary.darker
  },
  isDesktopActive: {
    color: theme.palette.primary.darker
  },
  isMobileActive: {
    color: theme.palette.primary.darker,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.darker,
      theme.palette.action.selectedOpacity
    )
  },
  onScroll: {
    '& $toolbar': {
      backgroundColor: theme.palette.grey[0]
    },
    '& $isHome': {
      color: theme.palette.text.darker
    },
    [theme.breakpoints.up('md')]: {
      '& $toolbar': {
        height: APP_BAR_DESKTOP - 20
      }
    }
  },
  buttonRounded: {
    borderRadius: 20,
    padding: theme.spacing(1, 3)
  }
}));

// ----------------------------------------------------------------------

function TopBar() {
  const classes = useStyles();
  const offset = useOffSetTop(100);
  const { isAuthenticated } = useAuth();

  return (
    <AppBar
      color="transparent"
      className={clsx(classes.root, { [classes.onScroll]: offset })}
      sx={{ boxShadow: 'none' }}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <Container
          maxWidth="lg"
          sx={{
            lineHeight: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo sx={{ height: 40 }} />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 1 }} />

          {isAuthenticated ? (
            <Account />
          ) : (
            <Button
              underline="none"
              variant="contained"
              component={Link}
              href={PATH_PAGE.auth.login}
              className={classes.buttonRounded}
            >
              LOG IN
            </Button>
          )}
        </Container>
      </Toolbar>
      {offset && (
        <Box
          sx={{
            left: 0,
            right: 0,
            bottom: 0,
            height: 24,
            zIndex: -1,
            margin: 'auto',
            borderRadius: '50%',
            position: 'absolute',
            width: `calc(100% - 48px)`,
            boxShadow: (theme) => theme.shadows[25].z8
          }}
        />
      )}
    </AppBar>
  );
}

export default TopBar;
