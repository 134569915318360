import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestore
} from 'react-redux-firebase';

// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth(method = 'firebase') {
  // Firebase Auth
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { auth, profile } = useSelector((state) => state.firebase);

  // Firebase Auth
  return {
    method: 'firebase',
    user: {
      displayName:
        auth.displayName || profile.firstName + ' ' + profile.lastName || '',
      email: auth.email || '',
      isPublic: profile.isPublic || false,
      role: profile.role || '',
      roleDisplayName: profile.roleDisplayName || '',
      company: profile.company || '',
      phone: profile.phone || '',
      designation: profile.designation || '',
      firstName: profile.firstName,
      lastName: profile.lastName
    },
    isLoading: !isLoaded(auth),
    isAuthenticated: !isEmpty(auth),

    login: ({ email, password }) =>
      firebase.login({
        email: email,
        password: password
      }),
    loginWithGoogle: () =>
      firebase.login({ provider: 'google', type: 'popup' }),

    loginWithFaceBook: () =>
      firebase.login({ provider: 'facebook', type: 'popup' }),

    loginWithTwitter: () =>
      firebase.login({ provider: 'twitter', type: 'popup' }),

    logout: () => firebase.logout(),

    resetPassword: (email) => firebase.resetPassword(email),

    updateProfile: ({ displayName, isPublic }) =>
      firebase.updateProfile({}).then((res) => {
        firestore.collection('users').doc(res.id).set(
          {
            displayName: displayName,
            isPublic: isPublic
          },
          { merge: true }
        );
      })
  };
}
