// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance'
};

export const PATH_HOME = {
  home: '/',
  privacyPolicy: '/privacy-policy',
  privacyPolicyDe: '/privacy-policy-de',
  privacyPolicyFr: '/privacy-policy-fr',
  privacyPolicyIt: '/privacy-policy-it',
  termsConditions: '/terms-conditions',
  termsConditionsDe: '/terms-conditions-de',
  termsConditionsFr: '/terms-conditions-fr',
  termsConditionsIt: '/terms-conditions-it',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/management/user/account'),
    dashboard: path(ROOTS.app, '/management/user/account')
  },
  management: {
    root: path(ROOTS.app, '/management'),
    user: {
      root: path(ROOTS.app, '/management/user'),
      account: path(ROOTS.app, '/management/user/account')
    }
  }
};
