import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  className: PropTypes.string
};

function Logo({ className, size, ...other }) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/cashew-logo-new.svg"
      className={className}
      sx={
        (size === 'large' && {
          height: { xl: '80px', lg: '80px', md: '80px', sm: '60px', xs: '60px' }
        }) || {
          height: { xl: '60px', lg: '40px', md: '60px', sm: '40px', xs: '40px' }
        }
      }
      {...other}
    />
  );
}

export default Logo;
